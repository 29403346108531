<template>
  <div class="my-register-page">
    <div class="my-container">
      <b-row class="mb-2">
        <b-col>
          <h1>{{ $t("Complaint") }}</h1>
        </b-col>
        <b-col class="mt-4" style="text-align:right">
          <b-button
            style="width:100px"
            @click="$router.go(-1)"
            variant="outline-danger"
          >
            {{ $t("back") }}
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple bordered>
        <b-thead>
          <b-tr variant="primary">
            <b-th style="vertical-align: middle">
              {{ $t("ComplaintdocDate") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("ComplaintrequestDocNumber") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("status") }}
            </b-th>
            <b-th style="text-align: center;vertical-align: middle">
              {{ $t("actions") }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in Complaint" :key="index">
            <b-td class="text-nowrap">
              {{ item.docDate }}
            </b-td>
            <b-td>
              {{ item.requestDocNumber }}
            </b-td>
            <b-td>
              {{ item.status }}
            </b-td>
            <b-td style="vertical-align: middle; text-align: center">
              <b-icon-eye-fill
                @click="Get(item)"
                style="cursor: pointer"
              ></b-icon-eye-fill>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div>
        <b-pagination
          v-model="filter.page"
          :total-rows="filter.totalRows"
          :per-page="filter.pageSize"
          first-number
          last-number
          @input="Refresh"
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <b-icon icon="chevron-double-left" size="18" />
          </template>
          <template #next-text>
            <b-icon icon="chevron-double-right" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ComplaintService from "@/services/complaint.service";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BPagination,
  BIconEyeFill,
} from "bootstrap-vue";
export default {
  data() {
    return {
      Complaint: [],
      filter: {
        page: 1,
        pageSize: 20,
        totalRows: 1,
      },
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BPagination,
    BIconEyeFill,
  },
  created() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      ComplaintService.GetList(this.filter)
        .then((res) => {
          this.Complaint = res.data.rows;
          this.filter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    Get(item) {
      this.$router.push({ name: "ComplaintView", params: { id: item.id } });
    },
  },
};
</script>

<style></style>
